import FBIcon from '../images/facebook (1).png'
import DiscordIcon from '../images/discord (2).png'
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="flex gap-[30px] flex-col text-center mt-[110px] md:mt-[50px] pb-[50px] px-[30px]">
                <p>ช่องทางการติดต่อ</p>
                <div className="flex gap-[30px] justify-center">
                    <Link target="_blank" to="https://www.facebook.com/dadafnth/" aria-label="Facebook"><img src={FBIcon} alt="" className='w-[32px] opacity-50 hover:opacity-100' /></Link>
                    <Link target="_blank" to="https://discord.com/invite/5t8Juy7FHu" aria-label="Discord"><img src={DiscordIcon} alt="" className='w-[32px] opacity-50 hover:opacity-100' /></Link>
                </div>
            </div>
    );
}
  
export default Footer;
