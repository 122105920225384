import arrow from '../images/arrow-down-sign-to-navigate.png'
import FBIcon from '../images/facebook (1).png'
import DiscordIcon from '../images/discord (2).png'
import dropDownIcon from '../images/menu.png'
import logo from '../images/dada-logo-horizontal.png'
import fortniteGGLogo from '../images/fortniteGG.png'

import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function HomeNavbar() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
    const [isGiftDropdownOpen, setGiftDropdownOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
    const [animateCollapseIn, setAnimateCollapseIn] = useState(false)

    const handleToggleDropdownOpen = () => {
        setDropdownOpen(true);
    };

    const handleToggleDropdownClose = () => {
        setDropdownOpen(false);
    };

    const handleToggleMobileDropdown = () => {
        if (isMobileDropdownOpen) {
            setAnimateCollapseIn(true)
        setTimeout(() => {
            setMobileDropdownOpen(false);
            setAnimateCollapseIn(false)
        }, 495);
        }
        setMobileDropdownOpen(true);
    };

    const handleToggleGiftDropdown = () => {
        setGiftDropdownOpen(!isGiftDropdownOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.outerWidth < 920);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);
    
    return (
        <div className={`${isMobile ? 'gap-[15px]' : 'gap-[27px]'} bg-white w-full  py-[10px] pr-[100px] pl-[90px] lg:pl-[20px] lg:pr-[30px] flex flex-col  items-center sticky top-0  z-30 navbar `}>
            <div className='w-full flex items-center'>
                <Link to="/" className="font-light h-min">
                    <img src={logo} alt="logo" className='h-[80px]' />
                </Link>
                {!isMobile && <><div className="flex gap-[50px] mx-auto middle-navbar">
                <Link target="_blank" to="https://fortnite.gg/shop" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                    Item Shop
                    <br /> 
                    <img src={fortniteGGLogo} alt="fortniteGGLogo" className='inline-flex h-[16px] mr-[5px]' />
                    <span className='text-[12px]'>Fortnite.gg</span>
                </Link>
                <Link to="/ItemPriceTable" className="my-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                    ราคา & ขั้นตอนการซื้อ
                </Link>
                <div className='my-auto relative group'>
                    <div
                        className="block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black cursor-pointer"
                        onMouseEnter={handleToggleDropdownOpen}
                        onMouseLeave={handleToggleDropdownClose}
                    >
                        <p className='relative inline-block'>คิวส่ง Gift<span className='inline-block w-[12px] ml-[5px]'><img src={arrow} alt="" /></span></p>
                    </div>
                    {isDropdownOpen && (
                        <div
                        className='bg-white absolute rounded-[20px] drop-down p-[15px] opacity-100 transition-opacity duration-300'
                        onMouseEnter={handleToggleDropdownOpen}
                        onMouseLeave={handleToggleDropdownClose}
                        >
                        <Link to="/CheckQueue/D1-D10" className="whitespace-nowrap relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                            Dada D1-D10
                        </Link>
                        <Link to="/CheckQueue/G1-G8" className="mt-[7px] whitespace-nowrap relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                        Dada G1-G8 <span className='text-red-600'>(ปิดรับเพื่อนแล้ว)</span> 
                        </Link>
                        </div>
                    )}
                </div>
                <Link to='/CheckPoints' className="my-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                    Dada Points
                </Link>
            </div>
            </>}
            {!isMobile && (
                <div className="flex gap-[20px]">
                    <Link target="_blank" to="https://www.facebook.com/dadafnth/" aria-label="Facebook"><img src={FBIcon} alt="" className='w-[24px] opacity-50 hover:opacity-100' /></Link>
                    <Link target="_blank" to="https://discord.com/invite/5t8Juy7FHu" aria-label="Discord"><img src={DiscordIcon} alt="" className='w-[24px] opacity-50 hover:opacity-100' /></Link>
                </div>
            )}
            {isMobile && (
                <div className='ml-auto flex align-center'>
                    <button onClick={handleToggleMobileDropdown} >
                        <img src={dropDownIcon} alt='Drop down icon' className='w-[24px]'></img>
                    </button>
                </div>
            )}
            </div>
            {isMobileDropdownOpen && 
                <div 
                    className={`w-full flex flex-col gap-[15px] pl-[10px] mb-[10px] ${animateCollapseIn ? ' animate-collapseOut' : 'animate-expandIn'} overflow-hidden`}
                >
                    <Link target="_blank" to="https://fortnite.gg/shop" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                        Item Shop
                        <br /> 
                    <img src={fortniteGGLogo} alt="fortniteGGLogo" className='inline-flex h-[16px] mr-[5px]' />
                    <span className='text-[12px]'>Fortnite.gg</span>
                    </Link>
                    <Link to="/ItemPriceTable" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                        ราคา & ขั้นตอนการซื้อ
                    </Link>
                    <button className='relative inline-block w-fit' onClick={handleToggleGiftDropdown}>คิวส่ง Gift<span className={`inline-block w-[12px] ml-[5px] transition-transform transform ${isGiftDropdownOpen && 'rotate-180'}`}><img src={arrow} alt="arrow" /></span></button>
                    {isGiftDropdownOpen && 
                    <>
                        <Link to="/CheckQueue/D1-D10" className="pl-[20px] whitespace-nowrap relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                        Dada D1-D10
                        </Link>
                        <Link to="/CheckQueue/G1-G8" className="pl-[20px] whitespace-nowrap relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                        Dada G1-G8 <span className='text-red-600'>(ปิดรับเพื่อนแล้ว)</span> 
                        </Link>
                    </>
                    }
                    <Link to='/CheckPoints' className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">
                        Dada Points
                    </Link>
                </div>
            }
        </div>
    );
}
  
export default HomeNavbar;
